:root {
  --accent: #61afef;
}

* {
  user-select: none;
  box-sizing: border-box;
}

body {
  background: #282c34;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.smalltalk .page {
  max-width: 500px !important;
  line-height: 150% !important;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.padded-wrap {
  padding: 1em 2em;
}
